

export default {
  name: 'OrganismMenuBarEmbedProject',
  data() {
    return {
      isScrolled: false
    }
  },
  computed: {
    i18nActive() {
      return !!this.$store.state.base.meta.generalConfig.i18nActive
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    showBackButton() {
      return this.$route.params.space
    },
  },
  methods: {}
}
